// $dimensions : (0:0px,5:5px, 10:10px,12:12px, 15:15px, 20:20px, 25:25px, 30:30px, 35:35px, 40:40px, 50:50px, 60:60px, 80:80px, 100:100px, 15p:15%, 25p:25% ,6p:6%);


$dimensions : (0:0, 3:0.1875rem, 5:0.3125rem, 7:0.4375rem, 8:0.5rem, 10:0.625rem, 12:0.75rem, 15:0.9375rem, 16:1rem, 20:1.25rem, 24:1.5rem, 25:1.5625rem, 30:1.875rem, 32:2rem, 35:2.1875rem, 40:2.5rem, 45:2.8125rem, 50:3.125rem, 55:3.4375rem, 60:3.75rem, 65:4.0625rem, 70:4.375rem, 75:4.6875rem, 95:5.9375rem,
	100:6.25rem, 115:7.1875rem, 120:7.5rem, 170:10.625rem, m20:-1.25rem, 1p:1%, 3p:3%, 6p:6%, 7p:7%);

@each $key,
$value in $dimensions {
	.m-#{$key} {
		margin: $value;
	}

	.mv-#{$key} {
		margin: $value 0;
	}

	.mh-#{$key} {
		margin: 0 $value;
	}

	.ml-#{$key} {
		margin-left: $value;
		margin-right: 0;
	}

	.mr-#{$key} {
		margin-right: $value;
		margin-left: 0;
	}

	.mt-#{$key} {
		margin-top: $value;
	}

	.mb-#{$key} {
		margin-bottom: $value;
	}

	.p-#{$key} {
		padding: $value;
	}

	.pv-#{$key} {
		padding: $value 0;
	}

	.ph-#{$key} {
		padding: 0 $value;
	}

	.pl-#{$key} {
		padding-left: $value;
		padding-right: 0;
	}

	.pr-#{$key} {
		padding-right: $value;
		padding-left: 0;
	}

	.pt-#{$key} {
		padding-top: $value;
	}

	.pb-#{$key} {
		padding-bottom: $value;
	}


	[dir="rtl"] .ml-#{$key} {
		margin-right: $value;
		margin-left: 0;
	}

	[dir="rtl"] .mr-#{$key} {
		margin-left: $value;
		margin-right: 0;
	}

	[dir="rtl"] .pl-#{$key} {
		padding-right: $value;
		padding-left: 0;
	}

	[dir="rtl"] .pr-#{$key} {
		padding-left: $value;
		padding-right: 0;
	}

	.b-#{$key} {
		border: $value !important
	}

}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

	.ion-page,
	html,
	:host,
	ion-button {
		font-size: 14px !important;
	}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

	.ion-page,
	html,
	:host,
	ion-button {
		font-size: 14px !important;
	}
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

	.ion-page,
	html,
	:host,
	ion-button {
		font-size: 16px !important;
	}
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

	.ion-page,
	html,
	:host,
	ion-button {
		font-size: 16px !important;
	}
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

	.ion-page,
	html,
	:host,
	ion-button {
		font-size: 16px !important;
	}
}